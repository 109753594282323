import React from "react";
import Logo from "./images/NPSlogo.png";
import NPDB from "./images/NPDBlogo125.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">

          <ul className="list">
            <li>
              <div className="footer-image-wrapper">
                <img
                  src={Logo}
                  alt="National Park Service Logo"
                  width="70"
                  height="80"
                />
              </div>
            </li>
            <li><small>NPdB is made possible by</small></li>
            <li><small>National Park Service API</small></li>
            <li>
              <small>
                <a
                  href="https://www.nps.gov/subjects/developer/index.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  nps.gov/index.htm/
                </a>
              </small>
            </li>
          </ul>

          <div className="copyright">
            <ul className="list">
              <li>
                <div className="footer-image-wrapper">
                  <Link to="/">
                    <img
                      src={NPDB}
                      alt="National Park Database Logo"
                      width="85"
                      height="85"
                    />
                  </Link>
                </div>
              </li>
              <li><p>&copy; {new Date().getFullYear()} National Park Database</p></li>
            </ul>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
