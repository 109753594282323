// NotificationBar.js
import React from 'react';
import './NotificationBar.css'; // Custom styling for the notification

const NotificationBar = () => {
  return (
    <div className="notification-bar">
      <p>This project is in development. Please allow about a minute for the first load as the backend initializes.</p>
    </div>
  );
};

export default NotificationBar;