import React, { useContext, useRef } from "react";
import { NavLink } from "react-router-dom";
import { UserContext } from "./UserContext";
import NpdbLogo from "./images/NPDBlogo.png";

const Nav = () => {
  const { isAuthenticated, logout } = useContext(UserContext);
  const navbarRef = useRef(null); // Ref for the collapsible navbar

  const handleLogout = () => {
    logout();
    window.location.href = '/'; // Redirect to home after logout
  };

  const closeNavbar = () => {
    if (navbarRef.current && navbarRef.current.classList.contains('show')) {
      navbarRef.current.classList.remove('show');
    }
  };

  return (
    <nav class="navbar navbar-expand-lg custom-nav background-image">
      <div className="container-custom container">
        <div className="navbar-brand">
          <NavLink to="/" className="nav-link link display-7" onClick={closeNavbar}>
            <img src={NpdbLogo} className="logo-img" alt="logo" />
            
          </NavLink>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="material-icons-outlined">menu</span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent" ref={navbarRef}>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink to="/" className="nav-link link display-7" onClick={closeNavbar}>
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={isAuthenticated ? "/wishlist" : "/login"}
                className="nav-link link display-7"
                onClick={closeNavbar}
              >
                Wishlist
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={isAuthenticated ? "/visited" : "/login"}
                className="nav-link link display-7"
                onClick={closeNavbar}
              >
                Visited
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={isAuthenticated ? "/reviews/mine" : "/login"}
                className="nav-link link display-7"
                onClick={closeNavbar}
              >
                My Profile
              </NavLink>
            </li>
            
            {/* Uncomment this if you want to enable the live chat feature */}
            {/* <li className="nav-item">
              <NavLink
                to={isAuthenticated ? "/ws" : "/login"}
                className="nav-link link display-7"
                onClick={closeNavbar}
              >
                Live Chat
              </NavLink>
            </li> */}
              <li className="nav-item">
                <NavLink
                  to="/visitors"  // Always accessible, even if not logged in
                  className="nav-link link display-7"
                  onClick={closeNavbar}
                >
                  Visitors
                </NavLink>
              </li>
          </ul>
          <ul className="navbar-nav">
            {!isAuthenticated ? (
              <>
                <li className="nav-item">
                  <NavLink to="/signup" className="btn btn-primary display-7 me-3 custom-button" onClick={closeNavbar}>
                    Register
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/login"
                    className="btn btn-outline-primary display-7 custom-button"
                    onClick={closeNavbar}
                  >
                    Login
                  </NavLink>
                </li>
              </>
            ) : (
              <li className="nav-item">
                <button
                  className="btn btn-primary display-7 custom-button"
                  onClick={() => { handleLogout(); closeNavbar(); }}
                >
                  Log Out
                </button>
              </li>
            )}
          </ul>

        </div>
      </div>
    </nav>
  );
};

export default Nav;
