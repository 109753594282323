import React from "react";
import { useNavigate, Link } from "react-router-dom";
import MapChart from "./MapChart";
import "./HomePage.css"; // ✅ your new CSS file

const HomePage = () => {
  const navigate = useNavigate();

  const mapHandler = (stateId) => {
    if (stateId) {
      navigate(`/parks/${stateId}`);
    }
  };

  return (
    <div className="container-fluid homepage-container">
      <div className="col-sm-6 flex-grow-1 justify-content-center">
        <MapChart mapHandler={mapHandler} />
      </div>
      <div className="col flex-shrink-1 justify-content-center">
        <div className="card text-dark bg-light mb-3">
          <div className="card-header welcome-card-header">
            <strong>Welcome to NPDB</strong>
          </div>
          <div className="card-body welcome-card-body">
            <h5 className="card-title">National Park Database</h5>
            <p className="card-text">
              Click a state to see all parks in that state.
            </p>
            <Link
              to="/most-wishlisted"
              className="btn shadow btn-outline-primary"
              style={{ margin: "10px" }}
            >
              Most Wishlisted National Parks
            </Link>
            <Link
              to="/most-visited"
              className="btn shadow btn-outline-primary"
              style={{ margin: "10px" }}
            >
              The Most Visited National Parks
            </Link>
            <p className="card-text">
              Register to track visits, make a wishlist, and write reviews.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
